// src/pages/Faq.js
import React, { useState, useEffect } from 'react';
import '../styles/Faq.css';

function Faq() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [bubbleAnimation, setBubbleAnimation] = useState(false);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What is Direct Air Capture (DAC)?",
      answer: "DAC is a technology designed to capture carbon dioxide (CO2) directly from the atmosphere for storage or reuse."
    },
    {
      question: "What is energy storage, and why is it important?",
      answer: "Energy storage systems store energy for later use, ensuring reliability, balancing supply and demand, and enabling the use of renewable energy sources like solar and wind."
    },
    {
      question: "How does CarbonBank combine DAC and energy storage?",
      answer: "CarbonBank integrates low-cost DAC with long-duration energy storage to create a sustainable, dual-purpose system for climate action."
    },
    {
      question: "What are the benefits of using DAC over traditional carbon capture methods?",
      answer: "DAC can be deployed anywhere, targets existing atmospheric CO2, and works independently of emission sources."
    },
    {
      question: "Why are you capturing Carbon?",
      answer: "Capturing carbon reduces CO2, combats climate change, achieves net-zero emissions, and supports sustainability efforts."
    },
    {
      question: "What are the benefits of carbon capturing from the environment?",
      answer: "Carbon capturing lowers greenhouse gases, mitigates climate change, protects ecosystems, improves air quality, and enables sustainable energy solutions for a healthier planet."
    }
  ];

  const filteredFaqData = faqData.filter(faq =>
    faq.question.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    const timer = setTimeout(() => setBubbleAnimation(true), 100); // Start bubble animation after a brief delay
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="faq-page">
      <div className={`faq-container ${bubbleAnimation ? 'bubble-animation' : ''}`}>
        <h1>Frequently Asked Questions</h1>
        
        <input
          type="text"
          className="faq-search"
          placeholder="Search FAQs..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <div className="faq-list">
          {filteredFaqData.length > 0 ? (
            filteredFaqData.map((faq, index) => (
              <div key={index} className={`faq-card ${activeIndex === index ? 'active' : ''}`}>
                <div className="faq-question" onClick={() => toggleAccordion(index)}>
                  <h3>{faq.question}</h3>
                  <span className={`icon ${activeIndex === index ? 'rotate' : ''}`}>&#9660;</span>
                </div>
                <div className={`faq-answer ${activeIndex === index ? 'show' : ''}`}>
                  <p>{faq.answer}</p>
                </div>
              </div>
            ))
          ) : (
            <p>No results found for "{searchTerm}".</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Faq;
