import ReactGA from 'react-ga4';

// Initialize Google Analytics with your Measurement ID
const initGA = () => {
  ReactGA.initialize('G-7EFVWS66GB'); // Replace 'G-XXXXXXXXXX' with your Measurement ID
};

const logPageView = (page) => {
  ReactGA.send({ hitType: 'pageview', page: page });
};

export { initGA, logPageView };
