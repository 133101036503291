import React, { useState } from 'react';
import '../styles/ContactUs.css';
// import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import {FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import emailjs from 'emailjs-com'; // Import EmailJS

function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contactNumber: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Email Validation using Regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      alert('Please enter a valid email address.');
      return;
    }

    // Send email using EmailJS
    emailjs.send('service_1v8d7d7', 'template_dfkcfms', formData, 'CUa4OGgHuc6k8Gi8r')
      .then((response) => {
        console.log('Email sent successfully!', response.status, response.text);
        alert('Form submitted successfully! We will back to you shortly.');
      }, (error) => {
        console.log('Error sending email:', error);
        alert('Failed to send message, please try again.');
      });
  };

  return (
    <div className="contactus-page">
      <div className="contactus-container">
        {/* Left Column: Contact Form */}
        <div className="contactus-form-section">
          <h1>Contact Us</h1>
          <p>We’d love to hear from you. Please fill out the form below.</p>
          <form onSubmit={handleSubmit} className="contact-form">
            <div className="input-group">
              <label htmlFor="name">Full Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your full name"
                required
              />
            </div>

            <div className="input-group">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
                required
              />
            </div>

            <div className="input-group">
              <label htmlFor="contactNumber">Phone Number (Optional)</label>
              <input
                type="text"
                id="contactNumber"
                name="contactNumber"
                value={formData.contactNumber}
                onChange={handleChange}
                placeholder="Enter your phone number"
              />
            </div>

            <div className="input-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Your message"
                required
              ></textarea>
            </div>

            <button type="submit" className="submit-btn">
              Send Message
            </button>
          </form>
        </div>

        {/* Right Column: Map, Address, and Social Media */}
        <div className="contactus-info-section">
          {/* Social Media Links */}
          <div className="social-media">
            <h3>Follow Us</h3>
            <div className="social-links">
             {/* <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="social-icon">
                <FaFacebookF />
              </a> */}
              <a href="https://x.com/ParthVe13132317" target="_blank" rel="noopener noreferrer" className="social-icon">
                <FaTwitter />
              </a>
              {/* <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="social-icon">
                <FaInstagram />
              </a> */}
              <a href="https://www.linkedin.com/in/parth-verma-912225195/?originalSubdomain=in" target="_blank" rel="noopener noreferrer" className="social-icon">
                <FaLinkedinIn />
              </a>
            </div>
          </div>

          {/* Map */}
          <div className="map-container">
            <h3>Our Location</h3>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56120.842760845015!2d77.44949379502165!3d28.462898093781497!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cc1e055d148a1%3A0x9f5207f7f0bc8f63!2sNoida%20Institute%20of%20Engineering%20and%20Technology%20(NIET%2C%20Greater%20Noida)!5e0!3m2!1sen!2sin!4v1732164691711!5m2!1sen!2sin"
              width="100%"
              height="300"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="Our Location"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          {/* Address */}
          <div className="company-address">
            <h3>Our Address</h3>
            <p>19, Institutional Area, Knowledge Park II,</p>
            <p> Greater Noida, Uttar Pradesh 201306</p>
          </div>

          {/* Contact Info */}
          <div className="contact-info">
            <h3>Contact Number</h3>
            <p>+91-8533883555</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;